import { Form } from 'antd'
import React, { useEffect, useState } from 'react'

import styled from 'styled-components';

const TableContainer = styled.div`
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableData = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
`;

const DownloadButton = styled.button`
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const ProductTable = ({ products }) => {
  const handleDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileUrl.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download file', error);
    }
  };

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader>Image</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Download</TableHeader>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <TableRow key={product._id}>
              <TableData>
                <img src={product.image} alt={product.name} width="50" />
              </TableData>
              <TableData>{product.name}</TableData>
              <TableData>
                <DownloadButton onClick={() => handleDownload(product.file)}>
                  Download
                </DownloadButton>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const Purchases = () => {
    const [purchasedProducts,setPurchasedProducts] = useState(JSON.parse(localStorage.getItem("userdata")).Products)
    const [isempty,setIsempty] = useState(true)
  return (
    <div>
      {purchasedProducts.length === 0 ? (<>
      <h2>No Products purchased Yet</h2>
      <Form><a href='/products'>Buy Products</a></Form>
      </>):
      (
        <>
       <h2>Product Lists</h2>
       <ProductTable products={purchasedProducts} />

        </>
      )}
    </div>
  )
}

export default Purchases
