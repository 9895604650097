import React from 'react'
import Login from './Login/Login'
import Addproducts from './AddProducts/Addproducts'

const Dashboard = () => {
  return (
    <div>
      {/* <Login /> */}
      <Addproducts />

    </div>
  )
}

export default Dashboard
